import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';

type P = {
    userCount: number
    setUserCount: Dispatch<SetStateAction<number>>
};

export const UserCountInput = ({ setUserCount, userCount }: P) => {
    const { t } = useTranslation()

    function handleUserCountChange(e: ChangeEvent<HTMLInputElement>) {
        const value = +e.target.value
        if (value < 0) return
        setUserCount(value)
    }

    return (
        <div className="relative flex flex-col gap-6 sm:flex-row sm:justify-center items-center">
            <span className="text-lg sm:text-2xl">
                {t('pricing.user-size.message')}
            </span>

            <input
                value={userCount}
                type="number"
                className="p-4 ring-2 ring-gray-100 rounded-md"
                placeholder="How many users?"
                onChange={handleUserCountChange}
            />
        </div>
    )
};
